import Introduction from './components/Introduction'
import Nice from './components/Nice'
import Lets from './components/Lets'
import Best from './components/Best'
import Happy from './components/Happy'
import Thanks from './components/Thanks'
import Cheers from './components/Cheers'
import Nuts from './components/Nuts'
import Goat from './components/Goat'
import Footer from './components/Footer'
import './components/scss/Embed.scss'
import './components/scss/Box.scss'

function App() {
  return (
    <div className='App'>
      <Introduction />
      <Lets />
      <Best />
      <Nice />
      <Happy />
      <Cheers />
      <Thanks />
      <Nuts />
      <Goat />
      <Footer />
    </div>
  )
}

export default App

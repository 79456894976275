import { useRef } from 'react'
import './scss/Cheers.scss'
import play from '../images/play.svg'
import close from '../images/close.svg'
import cover from '../images/CHEERS.jpg'

const playListId = '10Y73gcmtRhcnwZcm3YFDp'

function Cheers() {
  const embedRef = useRef(null)

  const handlePlay = () => {
    embedRef.current.classList.add('show')
    embedRef.current.nextSibling.classList.add('hide')
  }

  const handleClose = () => {
    embedRef.current.classList.remove('show')
    embedRef.current.nextSibling.classList.remove('hide')
  }

  return (
    <section className='cheers' id='Cheers'>
      <img
        className='cheers__drink-1'
        role={'presentation'}
        alt='An illustration of a beer mug.'
        src={'./images/cheers/Cheers-Beer.png'}
        srcSet={
          ('./images/cheers/Cheers-Beer.png',
          './images/cheers/Cheers-Beer@2x.png 2x')
        }
      />
      <img
        className='cheers__drink-2'
        role={'presentation'}
        alt='An illustration of a martini.'
        src={'./images/cheers/Cheers-Martini.png'}
        srcSet={
          ('./images/cheers/Cheers-Martini.png',
          './images/cheers/Cheers-Martini@2x.png 2x')
        }
      />

      <div className='cheers__fizz'>
        <span className='cheers__fizz__1'></span>
        <span className='cheers__fizz__2'></span>
        <span className='cheers__fizz__3'></span>
        <span className='cheers__fizz__4'></span>
        <span className='cheers__fizz__5'></span>
        <span className='cheers__fizz__6'></span>
        <span className='cheers__fizz__7'></span>
        <span className='cheers__fizz__8'></span>
        <span className='cheers__fizz__9'></span>
        <span className='cheers__fizz__10'></span>
        <span className='cheers__fizz__11'></span>
        <span className='cheers__fizz__12'></span>
        <span className='cheers__fizz__13'></span>
        <span className='cheers__fizz__14'></span>
        <span className='cheers__fizz__15'></span>
      </div>

      <div className='embed' ref={embedRef}>
        <iframe
          title='test'
          src={`https://embed.spotify.com/?uri=spotify%3Aplaylist%3A${playListId}`}
          width='300'
          height='380'
          frameBorder='0'
          allowtransparency='true'
        />

        <button onClick={handleClose}>
          <img src={close} alt='show album art' />
        </button>
      </div>

      <div className='box centered'>
        <img src={cover} alt='Cheers playlist cover' />
        <button onClick={handlePlay}>
          <img src={play} alt='start playlist' />
        </button>
      </div>
    </section>
  )
}

export default Cheers

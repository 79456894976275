import { useRef } from 'react'
import './scss/Lets.scss'
import play from '../images/play.svg'
import close from '../images/close.svg'
import cover from '../images/LETS.jpg'

const playListId = '6h83258CpBkD0B86aIsywH'

function Lets() {
  const embedRef = useRef(null)

  const handlePlay = () => {
    embedRef.current.classList.add('show')
    embedRef.current.nextSibling.classList.add('hide')
  }

  const handleClose = () => {
    embedRef.current.classList.remove('show')
    embedRef.current.nextSibling.classList.remove('hide')
  }

  return (
    <section className='lets' id='Lets'>
      <div className='lets__img1'>
        <img
          className='lets__img1'
          alt='An illustration of an 1800s woman.'
          src={'./images/lets-girl_background@1x.jpg'}
          srcSet={
            ('./images/lets-girl_background@1x.jpg',
            './images/lets-girl_background@2x.jpg 2x')
          }
        />

        <div className='lets__eyes'>
          <img
            className='lets__eyes__left'
            alt='an eye with a swirling line'
            src={'./images/lets-eyes.gif'}
          />
          <img
            className='lets__eyes__right'
            alt='an eye with a swirling line'
            src={'./images/lets-eyes.gif'}
          />
        </div>
      </div>
      <img
        className='lets__img2'
        alt='Flowers'
        src={'./images/lets-flowers@1x.png'}
        srcSet={
          ('./images/lets-flowers@1x.png', './images/lets-flowers@2x.png 2x')
        }
      />
      <img
        className='lets__img3'
        alt='Flowers'
        src={'./images/lets-flowers-2@1x.png'}
        srcSet={
          ('./images/lets-flowers-2@1x.png',
          './images/lets-flowers-2@2x.png 2x')
        }
      />
      <div className='embed' ref={embedRef}>
        <iframe
          title='test'
          src={`https://embed.spotify.com/?uri=spotify%3Aplaylist%3A${playListId}`}
          width='300'
          height='380'
          frameBorder='0'
          allowtransparency='true'
        />

        <button onClick={handleClose}>
          <img src={close} alt='show album art' />
        </button>
      </div>

      <div className='box centered'>
        {cover && <img src={cover} alt="Let's playlist cover" />}
        <button onClick={handlePlay}>
          <img src={play} alt='start playlist' />
        </button>
      </div>
    </section>
  )
}

export default Lets

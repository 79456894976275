import { useRef } from 'react'
import './scss/Best.scss'
import play from '../images/play.svg'
import close from '../images/close.svg'
import cover from '../images/BEST.jpg'

const playListId = '7fn6h2Q8O1JBJKa42EXTuH'

function Best() {
  const embedRef = useRef(null)

  const handlePlay = () => {
    embedRef.current.classList.add('show')
    embedRef.current.nextSibling.classList.add('hide')
  }

  const handleClose = () => {
    embedRef.current.classList.remove('show')
    embedRef.current.nextSibling.classList.remove('hide')
  }

  return (
    <section className='best' id='Best'>
      <div className='best__waves'>
        <img
          className='best__waves__1'
          role={'presentation'}
          alt='An illustration of a paintbrush marks, making a half circle.'
          src={'./images/best/best-wave-1.png'}
          srcSet={
            ('./images/best/best-wave-1.png',
            './images/best/best-wave-1@2x.png 2x')
          }
        />
        <img
          className='best__waves__2'
          role={'presentation'}
          alt='An illustration of a paintbrush marks, making a half circle.'
          src={'./images/best/best-wave-2.png'}
          srcSet={
            ('./images/best/best-wave-2.png',
            './images/best/best-wave-2@2x.png 2x')
          }
        />
        <img
          className='best__waves__3'
          role={'presentation'}
          alt='An illustration of a paintbrush marks, making a half circle.'
          src={'./images/best/best-wave-3.png'}
          srcSet={
            ('./images/best/best-wave-3.png',
            './images/best/best-wave-3@2x.png 2x')
          }
        />
        <img
          className='best__waves__4'
          role={'presentation'}
          alt='An illustration of a paintbrush marks, making a half circle.'
          src={'./images/best/best-wave-4.png'}
          srcSet={
            ('./images/best/best-wave-4.png',
            './images/best/best-wave-4@2x.png 2x')
          }
        />
      </div>
      <div className='best__trophy'>
        <img
          className='best__trophy__1'
          role={'presentation'}
          alt='An illustration of a trophy with paint brush strokes around it.'
          src={'./images/best/best-trophy.png'}
          srcSet={
            ('./images/best/best-trophy.png',
            './images/best/best-trophy@2x.png 2x')
          }
        />
      </div>
      <div className='embed' ref={embedRef}>
        <iframe
          title='test'
          src={`https://embed.spotify.com/?uri=spotify%3Aplaylist%3A${playListId}`}
          width='300'
          height='380'
          frameBorder='0'
          allowtransparency='true'
        />

        <button onClick={handleClose}>
          <img src={close} alt='show album art' />
        </button>
      </div>

      <div className='box centered'>
        <img src={cover} alt='Best playlist cover' />
        <button onClick={handlePlay}>
          <img src={play} alt='start playlist' />
        </button>
      </div>
    </section>
  )
}

export default Best

import { useRef } from 'react'
import './scss/Goat.scss'
import play from '../images/play.svg'
import close from '../images/close.svg'
import cover from '../images/GOAT.png'

const playListId = '6FqTgXg4WsbpZ8FfxrLj9C'

function Goat() {
  const embedRef = useRef(null)

  const handlePlay = () => {
    embedRef.current.classList.add('show')
    embedRef.current.nextSibling.classList.add('hide')
  }

  const handleClose = () => {
    embedRef.current.classList.remove('show')
    embedRef.current.nextSibling.classList.remove('hide')
  }

  return (
    <section className='goat' id='Goat'>
      <div className='embed' ref={embedRef}>
        <iframe
          title='test'
          src={`https://embed.spotify.com/?uri=spotify%3Aplaylist%3A${playListId}`}
          width='300'
          height='380'
          frameBorder='0'
          allowtransparency='true'
        />

        <button onClick={handleClose}>
          <img src={close} alt='show album art' />
        </button>
      </div>

      <div className='box centered'>
        <img src={cover} alt='GOAT playlist cover' />
        <button onClick={handlePlay}>
          <img src={play} alt='start playlist' />
        </button>
      </div>
    </section>
  )
}

export default Goat

import { useRef } from 'react'
import './scss/Nice.scss'
import play from '../images/play.svg'
import close from '../images/close.svg'
import cover from '../images/NICE.jpg'

const playListId = '4ylH3pJuilYrQiQQlWfq1h'

function Nice() {
  const embedRef = useRef(null)

  const handlePlay = () => {
    embedRef.current.classList.add('show')
    embedRef.current.nextSibling.classList.add('hide')
  }

  const handleClose = () => {
    embedRef.current.classList.remove('show')
    embedRef.current.nextSibling.classList.remove('hide')
  }

  return (
    <section className='nice' id='Nice'>
      <div className='nice__rows' id='scene'>
        <div className='nice__rows__item'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item' data-depth='0.3'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item' data-depth='0.3'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item' data-depth='0.3'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item' data-depth='0.3'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item' data-depth='0.3'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item' data-depth='0.3'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item' data-depth='0.3'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item' data-depth='0.3'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item' data-depth='0.3'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item'>
          <div className='nice__rows__item__image'></div>
        </div>
        <div className='nice__rows__item' data-depth='0.3'>
          <div className='nice__rows__item__image'></div>
        </div>
      </div>

      <div className='embed' ref={embedRef}>
        <iframe
          title='test'
          src={`https://embed.spotify.com/?uri=spotify%3Aplaylist%3A${playListId}`}
          width='300'
          height='380'
          frameBorder='0'
          allowtransparency='true'
        />

        <button onClick={handleClose}>
          <img src={close} alt='show album art' />
        </button>
      </div>

      <div className='box centered'>
        <img src={cover} alt='Nice playlist cover' />
        <button onClick={handlePlay}>
          <img src={play} alt='start playlist' />
        </button>
      </div>
    </section>
  )
}

export default Nice

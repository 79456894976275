import { useRef } from 'react'
import './scss/Happy.scss'
import play from '../images/play.svg'
import close from '../images/close.svg'
import cover from '../images/HAPPY.jpg'

const playListId = '6ZN0F0GSh5RaiHjqutRbS6'

function Happy() {
  const embedRef = useRef(null)

  const handlePlay = () => {
    embedRef.current.classList.add('show')
    embedRef.current.nextSibling.classList.add('hide')
  }

  const handleClose = () => {
    embedRef.current.classList.remove('show')
    embedRef.current.nextSibling.classList.remove('hide')
  }

  return (
    <section className='happy' id='Happy'>
      <div className='happy__face layer' data-depth='0.1'>
        <img
          className='happy__face__1 centered'
          alt='An illustration of boys face.'
          src={'./images/happy/happy-face.png'}
          srcSet={
            ('./images/happy/happy-face.png',
            './images/happy/happy-face@2x.png 2x')
          }
        />
      </div>
      <div className='happy__clown-1 layer' data-depth='0.3'>
        <img
          alt='An illustration of boys face.'
          src={'./images/happy/happy-clown-1.png'}
          srcSet={
            ('./images/happy/happy-clown-1.png',
            './images/happy/happy-clown-1@2x.png 2x')
          }
        />
      </div>
      <div className='happy__clown-2 layer' data-depth='0.4'>
        <img
          alt='An illustration of boys face.'
          src={'./images/happy/happy-clown-2.png'}
          srcSet={
            ('./images/happy/happy-clown-2.png',
            './images/happy/happy-clown-2@2x.png 2x')
          }
        />
      </div>
      <div className='happy__clown-3 layer' data-depth='0.5'>
        <img
          alt='An illustration of boys face.'
          src={'./images/happy/happy-clown-3.png'}
          srcSet={
            ('./images/happy/happy-clown-3.png',
            './images/happy/happy-clown-3@2x.png 2x')
          }
        />
      </div>

      <div className='embed' ref={embedRef}>
        <iframe
          title='test'
          src={`https://embed.spotify.com/?uri=spotify%3Aplaylist%3A${playListId}`}
          width='300'
          height='380'
          frameBorder='0'
          allowtransparency='true'
        />

        <button onClick={handleClose}>
          <img src={close} alt='show album art' />
        </button>
      </div>

      <div className='box centered'>
        <img src={cover} alt='Happy playlist cover' />
        <button onClick={handlePlay}>
          <img src={play} alt='start playlist' />
        </button>
      </div>
    </section>
  )
}

export default Happy

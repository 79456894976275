import { useRef } from 'react'
import './scss/Thanks.scss'
import play from '../images/play.svg'
import close from '../images/close.svg'
import cover from '../images/THANKS.jpg'
import mobileBg from '../images/Thanks-Mobile.jpg'

setTimeout(function () {
  const canvas = document.getElementById('thanks-canvas')
  const ctx = canvas.getContext('2d')
  let coord = { x: 0, y: 0 }
  let imageR = 1

  document.addEventListener('mousemove', start)
  window.addEventListener('resize', resize)

  // gradient
  const grad = ctx.createLinearGradient(0, 0, 1150, 1150)
  grad.addColorStop(0, '#D1499A')
  grad.addColorStop(1, '#FEF9FB')

  //svg
  const img1 = new Image()
  img1.src = '/images/thanks-line-1.png'
  const img2 = new Image()
  img2.src = '/images/thanks-line-2.png'
  const img3 = new Image()
  img3.src = '/images/thanks-line-3.png'

  resize()

  function resize() {
    ctx.canvas.width = window.innerWidth
    ctx.canvas.height = window.innerHeight
  }
  function reposition(event) {
    coord.x = event.clientX - canvas.offsetLeft
    coord.y = event.clientY - canvas.offsetTop
  }
  function start(event) {
    document.addEventListener('touchmove', draw)
    document.addEventListener('mousemove', draw)
    reposition(event)
  }

  function draw(event) {
    ctx.beginPath()
    ctx.lineCap = 'round'
    ctx.moveTo(coord.x, coord.y)
    reposition(event)
    ctx.lineTo(coord.x, coord.y)

    // move through 3 different images to make the line more full
    if (imageR === 1) {
      ctx.drawImage(img1, coord.x, coord.y)
      imageR = imageR + 1
    } else if (imageR === 2) {
      ctx.drawImage(img2, coord.x, coord.y)
      imageR = imageR + 1
    } else if (imageR === 3) {
      ctx.drawImage(img3, coord.x, coord.y)
      imageR = 1
    }
  }
}, 1000)

const playListId = '1QmkkRv7WmIDk45PI2GXlR'

function Thanks() {
  const embedRef = useRef(null)

  const handlePlay = () => {
    embedRef.current.classList.add('show')
    embedRef.current.nextSibling.classList.add('hide')
  }

  const handleClose = () => {
    embedRef.current.classList.remove('show')
    embedRef.current.nextSibling.classList.remove('hide')
  }

  return (
    <section className='thanks' id='Thanks'>
      <img src={mobileBg} alt='' role='presentation' />
      <canvas id='thanks-canvas'></canvas>

      <div className='embed' ref={embedRef}>
        <iframe
          title='test'
          src={`https://embed.spotify.com/?uri=spotify%3Aplaylist%3A${playListId}`}
          width='300'
          height='380'
          frameBorder='0'
          allowtransparency='true'
        />

        <button onClick={handleClose}>
          <img src={close} alt='show album art' />
        </button>
      </div>

      <div className='box centered'>
        <img src={cover} alt="Let's playlist cover" />
        <button onClick={handlePlay}>
          <img src={play} alt='start playlist' />
        </button>
      </div>
    </section>
  )
}

export default Thanks

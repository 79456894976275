import './scss/Footer.scss'

function Footer() {
  return (
    <footer className='footer'>
      <div className='footer__container'>
        <div className='footer__image'>
          <a className='footer-logo' href='https://www.walltowall.com/'>
            <svg width='90' height='90'>
              <g fill='#F4F47A' fillRule='nonzero'>
                <path d='M44.87 0C20.23 0 0 20.23 0 44.87c0 11.931 4.669 23.343 13.228 31.902C21.787 85.332 32.939 90 45.13 90 70.029 90 90 69.77 90 45.13 90 20.23 69.77 0 44.87 0Zm39.683 44.87c0 21.787-17.896 39.683-39.683 39.683-10.634 0-20.49-4.15-28.011-11.671C9.337 65.36 5.187 55.504 5.187 44.87c0-21.786 17.897-39.683 39.683-39.683 10.634 0 20.49 4.15 28.012 11.672 7.521 7.521 11.671 17.637 11.671 28.011Z' />
                <path d='M42.04 20h5.179v52H42.04zM28.833 50.527l-2.59-15.522h-4.402l-2.849 15.522-2.072-15.522H12l4.402 21.731h5.18l2.33-13.452 2.59 13.452h5.179l4.403-21.731h-4.921zM69.749 50.527l-2.59-15.522h-4.402l-2.849 15.522-2.071-15.522h-4.921l4.403 21.731h5.179l2.331-13.452 2.848 13.452h4.921L77 35.005h-4.92z' />
              </g>
            </svg>
          </a>
        </div>

        <div className='footer__content'>
          <div className='footer__content__1'>
            <p>WALL-TO-WALL STUDIOS</p>
            <p>Pittsburgh / Honolulu</p>
          </div>
          <div className='footer__content__2'>
            <p>
              Visit the W|W shop for the print version, in the form of
              occasional greeting cards
            </p>
          </div>
          <div className='footer__content__3'>
            <a
              href='https://shop.walltowall.com/'
              target='_blank'
              rel='noreferrer'
            >
              Go to Shop
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import './scss/Introduction.scss'
import thickLogo from '../images/record-thick.svg'

function Introduction() {
  return (
    <section className='introduction'>
      <div className='introduction__record'>
        <img
          src={thickLogo}
          alt='spinning wall-to-wall studios logo on a record'
        />
      </div>
      <div className='introduction__box centered'></div>

      <h1 className='introduction__title'>
        Occasional
        <br />
        Playlists
      </h1>
      <p className='introduction__copy'>
        Music for any occasion Curated&nbsp;by&nbsp;your friends at{' '}
        <a
          className='link'
          href='https://www.walltowall.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          W|W
        </a>
      </p>

      <div className='introduction__arrow'>
        <a className='scroll-to' href='#Lets'>
          <svg width='37' height='65' xmlns='http://www.w3.org/2000/svg'>
            <g stroke='#0B2EFF' strokeWidth='2' fill='none' fillRule='evenodd'>
              <path d='M18 0v38M2 38h33L18.5 63z' />
            </g>
          </svg>
        </a>
      </div>
    </section>
  )
}

export default Introduction
